body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica";
  background-attachment: fixed;
	background-color: #f9f9f9;
	background-image: url(/static/media/background-logo.f29ca609.png);
	background-position: center center;
	background-repeat: no-repeat;
  background-size: 150%;
  min-height: 400px;
  font-size: 14px;
  clear: both;
}

